
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6451 13.6621H15.6725C16.5335 13.6621 17.0787 13.1885 17.0787 12.4246C17.0787 11.7072 16.5335 11.1871 15.6725 11.1871H12.6451C12.5446 11.1871 12.4587 11.2734 12.4587 11.3596V13.4832C12.473 13.5837 12.545 13.6557 12.6451 13.6557V13.6621ZM12.6451 18.5551H15.7726C16.7341 18.5551 17.3079 18.0676 17.3079 17.2062C17.3079 16.4562 16.7626 15.8574 15.7726 15.8574H12.6451C12.5446 15.8574 12.4587 15.9436 12.4587 16.0299V18.3687C12.473 18.4835 12.545 18.5551 12.6451 18.5551ZM23.7215 0.0601037L14.7684 8.9851H16.232C18.5135 8.9851 19.862 10.4199 19.862 11.984C19.862 13.2215 19.0295 14.1215 18.137 14.5235C17.9934 14.5809 17.9934 14.786 18.1512 14.8392C19.1844 15.2405 19.916 16.3167 19.916 17.5797C19.916 19.3587 18.725 20.7792 16.415 20.7792H10.0764C9.97586 20.7792 9.88998 20.693 9.88998 20.6067V13.8489L0.0616078 23.6337C-0.0673922 23.7627 0.0184828 23.9926 0.205233 23.9926H23.8362C23.9225 23.9926 23.9937 23.9176 23.9937 23.8351V0.189479C24.0369 0.0319787 23.8362 -0.0542713 23.7211 0.0604787L23.7215 0.0601037Z" fill="#1D2433"/>
<path d="M13.1451 13.1621V13.1557H12.9587V11.6871H15.6725C16.0028 11.6871 16.2278 11.7858 16.3639 11.9084C16.4964 12.0277 16.5787 12.1991 16.5787 12.4246C16.5787 12.6801 16.492 12.8459 16.3688 12.9539C16.2382 13.0684 16.0146 13.1621 15.6725 13.1621H13.1451ZM9.38998 15.0522V20.6067C9.38998 20.8137 9.48901 20.9813 9.60103 21.0877C9.71618 21.1972 9.88248 21.2792 10.0764 21.2792H16.415C17.6745 21.2792 18.6851 20.8897 19.3825 20.2029C20.0788 19.5174 20.416 18.5817 20.416 17.5797C20.416 16.3617 19.823 15.27 18.8994 14.6652C19.6985 14.1164 20.362 13.1938 20.362 11.984C20.362 10.0908 18.7336 8.4851 16.232 8.4851H15.9782L23.4937 0.993134V23.4926H0.912039L9.38998 15.0522ZM15.7726 18.0551H12.9587V16.3574H15.7726C16.1659 16.3574 16.4192 16.4746 16.5698 16.6155C16.7193 16.7556 16.8079 16.9559 16.8079 17.2062C16.8079 17.5197 16.7079 17.7086 16.5725 17.8266C16.4271 17.9531 16.1745 18.0551 15.7726 18.0551Z" stroke="#1D2433" stroke-opacity="0.8"/>
</svg>

  </template>

  <script>
  export default {
    name: 'BigCommerce',
    inheritAttrs: true,
  }
  </script>
